const TYPES_LOGSROUTE = {
  setLoading: "set-loading",
  setErrorFetch: "set-error-fetch",
  setSuperusers: "set-superusers",
  setDataLogs: "set-data-logs",
  previewPage: "preview-page",
  nextPage: "next-page",
};

export default TYPES_LOGSROUTE;
