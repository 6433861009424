const TYPES_NOTIFICACION = {
  setloading: "set-loading",
  setNotifications: "set-notifications",
  setErrors: "set-errors",
  backPage: "back-page",
  nextPage: "next-page",
  setMsg: "Set-msg",
  moreNotifs: "more",
  checkUnseen: "check-unseen",
};
export default TYPES_NOTIFICACION;
